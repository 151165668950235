import {Component, Vue} from 'vue-property-decorator';

@Component
export default class DefaultAvatarMixin extends Vue {
  setAvatarImg(link: string | {target: Element} | undefined | null): string | undefined {
    if (link === undefined || link === null) {
      return require('@/assets/images/icons/upload.svg');
    }
    /* if html element */
    if (typeof link === 'object') {
      link.target.classList.add('defaultImg');
      (link.target as HTMLImageElement).src = require('@/assets/images/icons/upload.svg');
      return;
    }
    /* if casual link */
    return link;
  }

  setAvatarWithAltText(image, name) {
    if (image !== 'null' && image) {
        return `<img src="${this.setAvatarImg(image)}" alt="avatar">`;
      }
    if (name) {
      return `<div class="alt-text">${name[0].toUpperCase()}</div>`;
    }
    return `<div class="alt-text">?</div>`;
  }
}