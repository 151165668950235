import {Component, Vue} from 'vue-property-decorator';

@Component
export default class NoScrollMixin extends Vue {
  mounted() {
    document.body.classList.add('no-scroll');
  }

  destroyed() {
    document.body.classList.remove('no-scroll');
  }
}
