

















import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class ConfirmationModal extends Vue {
  @Prop({default: 'Are you sure?'}) readonly text!: string;
  @Prop() readonly subText!: string;
  @Prop({default: 'Yes'}) readonly submitText!: string;
  @Prop({default: 'No'}) readonly cancelText!: string;
  @Prop({default: 'modals'}) readonly portalTo!: string;
  @Prop({default: 'drop-menu'}) readonly mobileStrategy!: string;

  close(confirmed?: boolean) {
    this.$emit('confirm', confirmed);
  }
}
