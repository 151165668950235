











import {Component, Mixins} from 'vue-property-decorator';
import NoScrollMixin from '@/mixins/NoScrollMixin';

@Component({
  props: {
    mobileStrategy: String,
    backgroundColor: String,
    portalTo: {
      default: 'modals',
      type: String,
    },
    roundBorders: {
      default: true,
      type: Boolean,
    },
    animated: {
      type: Boolean,
      default: false,
    },
    showAnimation: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Modal extends Mixins(NoScrollMixin) {}
