

















import ClickOutside from '@/directives/clickOutside';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
  directives: {ClickOutside},
})
export default class DropDown extends Vue {
  showDropDown = false;

  @Prop({default: 'bottom left'}) readonly placement!: string;
  @Prop(String) readonly width!: string;
  @Prop(Boolean) readonly opened!: boolean;
  @Prop({default: true, type: Boolean}) readonly closeOnClick!: boolean;
  @Prop({type: Boolean, default: false}) readonly isMobileModal!: boolean;

  @Watch('opened') onOpenedChanged(NewV) {
    if (NewV !== this.showDropDown) {
      this.showDropDown = NewV;
      this.$emit('closed', this.showDropDown);
    }
  }

  openDropdown() {
    this.showDropDown = !this.showDropDown;
    this.$emit('closed', this.showDropDown);
  }

  clickEvent() {
    if (this.closeOnClick) {
      this.showDropDown = !this.showDropDown;
    }
  }
}
