




































































































































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import BioDetailsModal from '@/components/modals/BioDetailsModal.vue';
import TipsToModel from '@/components/modals/TipsToModel.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';
import NumbersMixin from '@/mixins/NumbersMixin';
import moment from 'moment';
import lineClamp from 'vue-line-clamp';
import PaymentPlan from '@/components/modals/ManageSubscriptionModal/PaymentPlan.vue';
import CCBillModal from '@/components/modals/CCBillModal.vue';
import ChoiceCardModal from '@/components/modals/ChoiceCardModal.vue';
import CCBillMessagesMixin from '@/mixins/CCBillMessagesMixin';
import CopyToClipboardMixin from '@/mixins/CopyToClipboardMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';
import ManageSubscriptionsModal from '@/components/modals/ManageSubscriptionModal/ManageSubscriptionsModal.vue';
import LoginOrRegisterModal from '@/components/modals/LoginOrRegisterModal.vue';
import ComingSoonModal from '@/components/modals/ComingSoonModal.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import Loader from '@/components/Loader.vue';

Vue.use(lineClamp);

@Component({
  components: {
    ComingSoonModal,
    ManageSubscriptionsModal,
    ChoiceCardModal,
    CCBillModal,
    PaymentPlan,
    TipsToModel,
    BioDetailsModal,
    LoginOrRegisterModal,
    VideoPlayer,
    Loader,
  },
})
export default class ModelProfileNew extends Mixins(
  NumbersMixin,
  CCBillMessagesMixin,
  CopyToClipboardMixin,
  DefaultAvatarMixin,
) {
  model: ModelDataInterface | null = null;
  showCCBillModel = false;
  showCardsModal = false;
  showSubscriptionModal = false;
  showLoginModal = false;
  showComingSoon = false;
  componentKey = 0;
  viewType = 'grid';
  paymentOptions: any = {
    provider: 'ccbill',
    performerId: '',
    type: '',
  };
  stats = {
    pictures: 0,
    previews: 0,
    products: 0,
    videos: 0,
    videosPlus: 0,
    picturesPlus: 0,
  };

  isRequestSend = false;
  isOpen = false;
  @Prop({type: String}) readonly id!: string;

  get tipsToModel() {
    return vxm.fan.showTipsModal;
  }

  set tipsToModel(val) {
    vxm.fan.setModalState({name: 'tips', status: val});
  }

  get statsAll() {
    return Object.values(this.stats).reduce((a, b) => a + b, 0);
  }

  get totalLikes() {
    return vxm.fan.modelTotalLikes;
  }

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get isMy(): boolean {
    if (this.isPerformer) {
      return this.id ? this.id === vxm.user.data.username : true;
    }
    return false;
  }

  get isSubscribed(): boolean {
    if (this.model && vxm.user.subscriptions.items) {
      return !!vxm.user.subscriptions.items.find(
        (x) => x.performerId === this.model?._id && moment(x.expiredDate).isAfter(moment()),
      );
    }
    return false;
  }

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  @Watch('id') onUserChanged() {
    this.forceRerender();
  }

  @Watch('showCardsModal') onHideModal() {
    if (!this.showCardsModal) {
      this.forceRerender();
    }
    const el = document.getElementById('tabTabs');
    if (el) {
      el.scrollIntoView({behavior: 'smooth'});
    }
  }

  mounted() {
    this.getUserData();
  }

  goToSelector() {
    const box = document.querySelector('#tabTabs') as HTMLElement;
    const offsetTop = box.offsetTop;
    setTimeout(() => {
      window.scrollTo({
        top: offsetTop - 150,
        // behavior: 'smooth',
      });
    }, 500);
  }

  closeTip() {
    this.tipsToModel = false;
    this.$router.push(`/${(this.model as ModelDataInterface).username}`);
  }

  forceRerender(withCardsUpdate = false) {
    if (withCardsUpdate) {
      vxm.user.paymentCards();
    }
    this.getUserData();
    this.componentKey += 1;
  }

  getUserData() {
    if (!this.id) {
      return this.$router.push('not-found');
    }
    let id = this.id;
    if (!id && vxm.user.data.role === 'performer') {
      id = vxm.user.data._id;
    }

    this.isRequestSend = true;

    vxm.fan
      .getPerformer(id)
      .then((res) => {
        this.model = res.data;
        this.paymentOptions.performerId = res.data._id;
        if (vxm.user.token) {
          vxm.user.getSubscriberBills();
        }
        this.tipsToModel = this.$route.query.showTips === 'true';
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
    vxm.fan
      .getPerformerStats(id)
      .then((res) => {
        this.stats = res.data;
      })
      .catch(() => this.$router.push('not-found')); // Redirect to 404 page
    vxm.fan.performerView(id);
  }

  toMessages() {
    vxm.general
      .createMessagesGroups({recipientId: this.model?._id, type: this.isPerformer ? 'model' : 'subscriber'})
      .then((res) => {
        this.$router.push({
          path: `/${this.isPerformer ? 'model' : 'fan'}/messages`,
          query: {chatId: res.data._id, chatType: this.isPerformer ? 'model' : null},
        });
      });
  }

  payForSubscribe(plan: string) {
    if (this.model && this.model.isPayable) {
      this.paymentOptions.type = plan;
      if (vxm.user.userCards && vxm.user.userCards.length) {
        this.showCardsModal = true;
      } else {
        vxm.user.paymentSubscription(this.paymentOptions).then((res) => {
          // this.showCCBillModel = true;
          setTimeout(() => {
            window.open(vxm.user.CCBillFormUrl, '_blank');
          });
        });
      }
    } else {
      this.showComingSoon = true;
    }
  }

  selectedPayment(id: string) {
    if (id) {
      this.paymentOptions.transactionId = id;
    } else {
      delete this.paymentOptions.transactionId;
    }
    vxm.user
      .paymentSubscription(this.paymentOptions)
      .then(() => {
        if (!this.paymentOptions.transactionId) {
          // this.showCCBillModel = true;
          setTimeout(() => {
            window.open(vxm.user.CCBillFormUrl, '_blank');
          });
          this.showCardsModal = false;
        } else {
          window.addEventListener('message', this.receiveMessage);
          this.hiddenIframe = document.createElement('iframe');
          this.hiddenIframe.setAttribute('src', vxm.user.CCBillFormUrl);
          document.body.appendChild(this.hiddenIframe);
        }
      })
      .catch(() => {
        this.showCardsModal = false;
      });
  }

  getThisHref() {
    return window.location.origin + '/' + this.model?.username;
  }

  checkRedirect(link: string) {
    if (!this.isLogin) {
      this.showLoginModal = true;
    } else if (this.$route.path !== link) {
      this.$router.push(link);
    }
  }
}
