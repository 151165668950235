import {Vue, Component} from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class MomentDateMixin extends Vue {
  nextBillingDate(date) {
    return moment(String(date)).format('MMM DD, YYYY');
  }

  monthDate(date) {
    return moment(String(date)).format('MMM Do');
  }

  formatDate(date) {
    return moment(date).format('MM-DD-YY');
  }

  formatDateMonth(date) {
    return moment(date).format('DD MMM YYYY');
  }

  formatDateSlash(date) {
    return moment(date).format('MM/DD/YY');
  }

  formatTime(date) {
    return moment(date).format('HH:mm');
  }

  isExpired(date) {
    return moment(date).diff(moment()) <= 0;
  }

  createdAt(time: string) {
    const hour = 3600000;
    const day = 86400000;
    const min = 60000;

    const dateDiff = moment()
      .utc(false)
      .diff(time);
    if (min - dateDiff > 0) {
      return `now`;
    }
    if (hour - dateDiff > 0) {
      return `${moment(dateDiff)
        .utc(false)
        .format('m')} min`;
    }
    if (day - dateDiff > 0) {
      const hours = moment(dateDiff)
        .utc(false)
        .format('H');
      return `${hours} hour${hours === '1' ? '' : 's'}`;
    }
    return moment(time)
      .utc(false)
      .format('MM/DD/YY');
  }
}
