





























































import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '../Modal.vue';
import {ModelDataInterface} from '@/types/userDataInterface';

@Component({
  components: {Modal},
})
export default class ViewsModal extends Vue {
  showModal = false;

  @Prop() readonly model!: ModelDataInterface;

  close() {
    this.showModal = false;
  }
}
