import {Component, Vue} from 'vue-property-decorator';

@Component
export default class NumbersMixin extends Vue {
  numWithOneDecimal(value): number {
    return parseFloat(value.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]);
  }
  numWithCommas(value): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  numbFormat(value: number): string {
    if (!value) {
      return 0 + '';
    }
    if (value >= 1000000) {
      return this.numWithOneDecimal(value / 1000000) + 'M';
    }
    if (value >= 10000) {
      return this.numWithOneDecimal(value / 1000) + 'K';
    }
    if (value >= 1000) {
      return this.numWithCommas(value);
    }
    return (('' + value).split('.')[1] || '').length > 2 ? value.toFixed(2) : value + '';
  }

  sizeFormat(value: number): string {
    const type = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let i = 0;

    while (value / 1024 >= 1 && i < type.length - 1) {
      value /= 1024;
      i++;
    }

    return value.toFixed(2) + ' ' + type[i];
  }
}
